module.exports = {
  siteTitle: 'The Late Show Late Show', // <title>
  manifestName: 'Grayscale',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'The Late Show Late Show',
  subHeading: 'at Lahe Lahe ',

  // social
  socialLinks: [

 
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/DevashishMulye',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/devashish.mulye/?hl=en',
    },

  ],
  email: 'devashish.mulye@gmail.com',
  phone: '8609283032',
  address: 'No. 2906, 2907, HAL 2nd Stage, Kodihalli, Bengaluru, Karnataka 560008',
};
